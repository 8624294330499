<template>
    <div class="resol-tree-wrapper">
        
        <div class="resol-tree-header">
            <div style="width: 80%;padding-left:18px;">{{ $t("Текст") }}</div>
            <div style="width: 10%;">{{ $t("Контрольный_срок") }}</div>
            <div style="width: 10%;">{{ $t("Статус") }}</div>
        </div>

         <div class="resol-tree">
            <div class="div-table-rows-container">

                <!--#region Резолюция-->
                <div
                    v-if="executionTree"
                    :class="[activeId == resolution.id ? 'resol-tree-active' : '' ,'div-table-row']"
                    @click.stop="taskClickHandler(resolution)"
                    @dblclick.stop="taskDblClick(resolution)"
                >
                    <!--#region Ячейка Текст-->
                    <div class="div-table-row-cell" :style="{ 'width': `calc(80% - 0px)`, 'margin-left': `0px` }" >
                        
                        <div class="elipsis-text-wrap">
                            <!-- <span class="last-with-line" v-if="!isParent && margin != 0"></span> -->
                            <!--Toggle-->
                            <span v-if="!!executers && !!executers.length">
                                <i :class="[ openedIds.includes(resolution.id) ? 'fas fa-caret-down' : 'fas fa-caret-right']" @click.stop="toggle(resolution)" @dblclick.stop="() => {}"></i>
                            </span>
                            <span v-if="!executers || !executers.length" class="resol-tree-no-icon-area"></span>

                            <template v-if="isOrderTree">
                                {{ $t('Личное_поручение') }}
                            </template>
                            <!-- Если это не личное поручение -->
                            <template v-else>
                                <template v-if="resolution.text == '4'">
                                    <span>{{ $t("Пункт") }}</span>
                                </template>
                                <template v-else>
                                    <span>{{ $t("Карточка_резолюции_КР") }}</span>
                                </template>

                                <span>{{ $moment(resolution.viewInfo.createDate).format('DD.MM.YYYY HH:mm:ss') }}</span>

                                <v-employee-chip
                                    :id="resolution.viewInfo.authorEmployeeId"
                                    :name="resolution.viewInfo.author"
                                    :is-employee="true"
                                />

                                <i v-if="resolution.viewInfo.signerControl" class="fas fa-glasses signer-control-i" v-tooltip.top-center="isSignerControlTooltip"></i>

                                <div class="etw-text-container">
                                    <span class="etw-text">{{ resolution.viewInfo.text }}</span>
                                </div>
                            </template>

                        </div>

                    </div>
                    <!--#endregion-->

                    <!--#region Ячейка Контрольный срок-->
                    <div class="div-table-row-cell" style="width: 10%;">
                        <span v-if="resolution.viewInfo.toDate">
                            {{ $moment(resolution.viewInfo.toDate).format('DD.MM.YYYY') }}
                        </span>
                    </div>
                    <!--#endregion-->

                    <!--#region Ячейка Статус-->
                    <div class="div-table-row-cell" style="width: 10%;">
                        <div class="onlyReadData more-per-lab-wrap">
                            <StatusChip :status="availableStatuses[$helpers.getTaskStatus(parseInt(resolution.viewInfo.status, 10))]" />
                            <StatusChip v-if="resolution.viewInfo.canSendStatus" :status="availableStatuses[$options.rssStatusKeys[resolution.viewInfo.canSendStatus]]" />
                        </div>
                    </div>
                    <!--#endregion-->
                    
                </div>
                <!--#endregion-->

                <!--#region Исполнители-->
                <template v-for="executer in executers">
                    <div                    
                        v-show="openedIds.includes(resolution.id)"
                        :key="executer.id"
                        :class="[activeId == executer.id ? 'resol-tree-active' : '' ,'div-table-row']"
                        @click.stop="taskClickHandler(executer)"
                        @dblclick.stop="taskDblClick(executer)"
                    >
                        <!--#region Ячейка Текст-->
                        <div class="div-table-row-cell" :style="{ 'width': `calc(80% - 30px)`, 'margin-left': `30px` }" >
                            
                            <div class="elipsis-text-wrap">
                                <span v-if="!executer.nodes || !executer.nodes.length" class="last-with-line"></span>
                                <!--Toggle-->
                                <span v-if="!!executer.nodes && !!executer.nodes.length">
                                    <i :class="[ openedIds.includes(executer.id) ? 'fas fa-caret-down' : 'fas fa-caret-right']" @click.stop="toggle(executer)" @dblclick.stop="() => {}"></i>
                                </span>
                                <!-- <span v-if="!executer.nodes || !executer.nodes.length" class="resol-tree-no-icon-area"></span> -->

                                <span>{{ $t("Исполнитель") }}</span>

                                <v-enterprise-chip
                                    v-if="executer.viewInfo.executerId === '00000000-0000-0000-0000-000000000000'"
                                    :name="executer.viewInfo.author"
                                    :iconColor="getWorkPlaceChipColor(executer.viewInfo.status.toLowerCase())"
                                    :main="executer.values[6]=='True'"
                                />
                                <v-workplace-chip
                                    v-else
                                    :id="executer.viewInfo.executerId" 
                                    :name="executer.viewInfo.author"
                                    :iconColor="getWorkPlaceChipColor(executer.viewInfo.status.toLowerCase())"
                                    :main="executer.values[6]=='True'"
                                    :is-employee="true"
                                />

                            </div>

                        </div>
                        <!--#endregion-->

                        <!--#region Ячейка Контрольный срок-->
                        <div class="div-table-row-cell" style="width: 10%;"></div>
                        <!--#endregion-->

                        <!--#region Ячейка Статус-->
                        <div class="div-table-row-cell" style="width: 10%;">
                            <StatusChip :status="availableStatuses[$helpers.getTaskStatus(parseInt(executer.values[2], 10))]" />
                        </div>
                        <!--#endregion-->
                        
                    </div>

                    <!--#region Исполнения исполнителя-->
                    <template v-for="execution in allExecutions ? executer.nodes.filter(n => n.text=='2') : executer.nodes.filter(n => n.text=='2').slice(0, 1)">
                        <div    
                            v-show="openedIds.includes(executer.id) && openedIds.includes(resolution.id)"                
                            :key="execution.id"
                            :class="[activeId == execution.id ? 'resol-tree-active' : '' ,'div-table-row']"
                            @click.stop="taskClickHandler(execution)"
                            @dblclick.stop="taskDblClick(execution)"
                        >
                            <!--#region Ячейка Текст-->
                            <div class="div-table-row-cell" :style="{ 'width': `calc(80% - 60px)`, 'margin-left': `60px` }" >
                                
                                <div class="elipsis-text-wrap">
                                    <span class="last-with-line"></span>

                                    <span>{{ $t("Карточка_исполнения_КИ") }}</span>

                                    <i 
                                        class="fa fa-chevron-right"
                                        style="color:#125780; margin-left: 5px;margin-right: 5px;"
                                    ></i>

                                    <template v-if="!!execution.viewInfo.status">
                                        <i class="fa fa-times" style="color: #d9534f;margin-right: 5px;"></i>
                                        <text>{{ $t("Отклонено") }}: {{ execution.viewInfo.status }}</text>
                                    </template>

                                    <span>
                                        {{ $moment(execution.viewInfo.createDate).format('DD.MM.YYYY') }}
                                    </span>

                                    <div class="etw-text-container">
                                        <span class="etw-text" v-tooltip.top-center="execution.viewInfo.text">
                                            {{ execution.viewInfo.text }}
                                        </span>
                                    </div>

                                </div>

                            </div>
                            <!--#endregion-->

                            <!--#region Ячейка Контрольный срок-->
                            <div class="div-table-row-cell" style="width: 10%;"></div>
                            <!--#endregion-->

                            <!--#region Ячейка Статус-->
                            <div class="div-table-row-cell" style="width: 10%;"></div>
                            <!--#endregion-->
                            
                        </div>
                    </template>
                    <!--#endregion-->
                </template>
                <!--#endregion-->
            </div>
        </div>

    </div>
</template>

<script>
import i18n from '@/i18n'
import { mapGetters, mapActions } from 'vuex';
import StatusChip from '@/components/StatusChip'
import { httpAPI } from "@/api/httpAPI";
import Axios from 'axios';

export default {
    name: "ExecutionTree",
    rssStatusKeys:["", "rssOnSign", "rssSignedByChief", "rssSended"],
    props: {
        taskClick: Function,
        taskDblClick: Function,
        allExecutions:  {
            type: Boolean,
            default: false
        },
        expanded:  {
            type: Boolean,
            default: true
        },
        isOrderTree: {
            type: Boolean,
            default: false
        },
        isEditMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        StatusChip      
    },
    data() {
        return {
            activeId: null,
            openedIds: [],
            innerExecutersIds:[],
            cancellationTokenSorce: null
        }
    },
    computed: {        
        ...mapGetters('actionsource', { executionTree: 'getExecutionTree'}),
        availableStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        isSignerControlTooltip () {
            return i18n.t("Личный_контроль");
        },
        resolution() {  
            return this.executionTree?.[0];
        },
        executers() {
            if(this.resolution?.values.length >= 15 && this.resolution.values[14] == "True")
                return this.resolution?.nodes;

            return this.resolution?.nodes.filter(n => this.innerExecutersIds.includes(n.viewInfo.executerId));            
        },
    },
    methods: {
        ...mapActions('references', ['getWorkplaces']),
        ...mapActions('actionsource', ['updateDataSource', 'updateExecutionTree']),
        toggle(item) {

            let isOpen = false;
            if (this.openedIds.find(i => i == item.id))            
                this.openedIds.splice(this.openedIds.indexOf(item.id), 1);            
            else
            {
                this.openedIds.push(item.id);
                isOpen = true;
            }

            if (item == this.resolution)
                this.$emit('update:expanded', isOpen)
        },
        getWorkPlaceChipColor(key) {
            switch(key) {
                case "success": return "var(--d-green)!important";
                case "warning": return "var(--d-yellow)!important";
                case "info": return "#494F59!important";
                case "danger": return "var(--d-red)";
                case "default": return "#494F59";                
            }
        },
        async taskClickHandler(item)
        {
            if (this.isEditMode)
                return;
                
            if (["0", "2"].includes(item.text))
                return;
                
            this.activeId !== item.id
                ? this.activeId = item.id
                : this.activeId = null;

            //обновляем значение выбранного исполнителя в состоянии, для возможных действий над пунктом/резолюцией/поручением/нарядом
            this.$store.commit('actionsource/SET_ACTIVE_EXECUTER', this.activeId);

            let resolutionid = item.viewInfo.resolutionId;
            let taskid = this.resolution.id;

            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/tasks/getmenu?resolutionid=${resolutionid}&taskid=${taskid}&executerid=${this.activeId ?? '00000000-0000-0000-0000-000000000000'}`,
                method: 'GET',
                headers: { 'isCommon': this.$store.getters['actionsource/isDataSourceCommon'] },
                cancelToken: this.cancellationTokenSorce.token,
            });

            if (response)
                this.updateDataSource({ property: 'Data.Menu', value: response.data.payload.Data.Menu });
            else
            {
                let defaultMenuResponse = await httpAPI({
                    url: `api/tasks/getmenu?resolutionid=${resolutionid}&taskid=${taskid}&executerid=00000000-0000-0000-0000-000000000000`,
                    method: 'GET',
                    headers: { 'isCommon': this.$store.getters['actionsource/isDataSourceCommon'] },
                    cancelToken: this.cancellationTokenSorce.token,
                });

                if (defaultMenuResponse)
                    this.updateDataSource({ property: 'Data.Menu', value: defaultMenuResponse.data.payload.Data.Menu });
                else
                    this.updateDataSource({ property: 'Data.Menu', value: [] });
            }

            if (this.taskClick)
                this.taskClick();
        },
    },
    async created()
    {   
        this.innerExecutersIds = (await this.getWorkplaces()).InnerExecuters.Executers.map(e => e[0]);
    },
    watch: {
        expanded(val) {
            if (val)
                this.openedIds = [this.resolution.id, ...this.executers.map(i => i.id)];
            else
                this.openedIds = [];
        }        
    },
}
</script>
